@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
   url("./fonts/Helvetica-Font/Helvetica-Bold.ttf") format("truetype");
  }

body {
  margin: 0px !important;
  font-family: "Helvetica";
  
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reveal {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 30px;
  height: 30px;
  margin-left: 50%;
  position: relative;
  left: -50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}